import React from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Container, Row,
  
} from "reactstrap"



import SalesAnalytics from './SalesAnalytics';
// import EarningReport from './EarningReports';
// import LatestTransactions from './LatestTransactions';
// import LatestOrders from './LatestOrders';
import TimestampTraffic from './TimestampTraffic';
import TotalVehicleCount from './TotalVehicleCount';




import  Widget from './Widget';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
// import Buttons from './Buttons';
import AllTollPlaza from './AllTollPlaza';
// import View from './View';
// import TollPlaza from './TollPlaza';
// import Vehicle from './Vehicle';
// import Cameras from './Cameras';



const Dashboard = () => {
  document.title = "Dashboard | React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>


        
          <Breadcrumbs title="Pages" breadcrumbItem="Dashboard" />

          {/* <Row>
                <Buttons />
             </Row> */}
             {/* <Row>
             <Col xl={3}>
                <Cameras />
                </Col>
                <Col xl={6}>
                <Vehicle/>
                </Col>
                <Col xl={3}>
                <TollPlaza />
                </Col>
              </Row> */}
             
             <Widget />
             
             <Row>
             <Col xl={8}>
             <SalesAnalytics />
           
             </Col>
             <Col xl={4}>
             <TotalVehicleCount />
             </Col>
            
            {/* <EarningReport /> */}
          </Row>
          <Row>
            <Col xl={6}>
              {/* <LatestTransactions /> */}
             
              <AllTollPlaza />
            </Col>
            <Col xl={6}>
            <TimestampTraffic />

              {/* <LatestOrders loading={false} /> */}
            </Col>
          </Row>
          <Row >
          
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)




  {/* <SalesAnalytics />
            <EarningReport />
             <LatestTransactions />
             <LatestOrders  /> */}