import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col, Row } from "reactstrap";

const widget = [
  {
    id: 1,
    title: "Total Vehicle Count",
    count: "230543",
    dollor: true,
    icon: "mdi mdi-cash-multiple text-primary",
    percentage: "2.65%",
    color: "success",
    upArrow: true,
  },
  {
    id: 2,
    title: "Vehicle Flow (LHR to ISB)",
    count: "122765",
    dollor: false,
    icon: "mdi mdi-cart-check text-success",
    percentage: "0.55%",
    color: "warning",
    upArrow: true,
  },
  {
    id: 3,
    title: "Vehicle Flow (ISB to LHR)",
    count: "211657",
    dollor: false,
    icon: "mdi mdi-account-group text-primary",
    percentage: "14.33%",
    color: "success",
    upArrow: true,
  },
  {
    id: 4,
    title: "Busy Toll Plaza (Rawat)",
    count: 34000,
    values: "Rawat",
    dollor: false,
    icon: "mdi mdi-refresh-circle text-success",
    percentage: "4.58%",
    color: "danger",
    upArrow: false,
  },


];

const Widget = (props) => {
  return (
    <React.Fragment>
      <Row>
        {widget.map((widget, key) => (
          <Col md={6} xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="avatar-sm mx-auto mb-4">
                    <span className="avatar-title rounded-circle bg-light font-size-24">
                      <i className={widget.icon}></i>
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-muted text-uppercase fw-semibold font-size-13">
                    {widget.title}
                  </p>
                  <h4 className="mb-1 mt-1">
                    {widget.dollor === true ? "" : ""}
                    <span className="counter-value" data-target="58425">
                      {widget.count === 0 ? (
                        widget?.values
                      ) : (
                        <CountUp end={widget.count} />
                      )}
                    </span>
                  </h4>
                </div>
                {/* <div>
    <p className="text-muted text-uppercase fw-semibold font-size-13">{widget.title}</p>
    <h4 className="mb-1 mt-1">
        {widget.dollor && '$'}
        <span className="counter-value" data-target="58425">
            {widget.values 
                ? (
                    <>
                        <CountUp end={widget.count.car} /> CAR
                        <br />
                        <CountUp end={widget.count.bus} /> BUS
                    </>
                  )
                : <CountUp end={widget.count.values} />
            }
        </span>
    </h4>
</div> */}

                <p className="text-muted mt-3 mb-0">
                  <span
                    className={"badge badge-soft-" + widget.color + " me-1"}
                  >
                    {widget.upArrow === true ? (
                      <i className="mdi mdi-arrow-up-bold me-1"></i>
                    ) : (
                      <i className="mdi mdi-arrow-down-bold me-1"></i>
                    )}
                    {widget.percentage}
                  </span>{" "}
                  Last synched 40 minutes ago
                </p>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default Widget;
