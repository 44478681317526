// import PropTypes from "prop-types";
// import React, { useState } from "react";
// import { Label } from "reactstrap";
// import Switch from "react-switch";
// import Select from "react-select";

// import { connect } from "react-redux";

// import { Link } from "react-router-dom";

// // Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import logoSm from "../../assets/images/logo-sm.png";
// import logoSmLight from "../../assets/images/logo-sm-light.png";
// import logoDark from "../../assets/images/logo-dark.png";
// import logoLight from "../../assets/images/logo-light.png";

// //i18n
// import { withTranslation } from "react-i18next";

// // Redux Store
// import {
//   showRightSidebarAction,
//   toggleLeftmenu,
//   changeSidebarType,
// } from "../../store/actions";
// import DateRangeButton from "../../pages/Dashboard/DateRangeButton";

// const optionGroup = [
//   {
//     label: "All Traffic",
//     // options: [
//     //   { label: "Mustard", value: "Mustard" },
//     //   { label: "Ketchup", value: "Ketchup" },
//     //   { label: "Relish", value: "Relish" },
//     // ],
//   },
//   {
//     label: "TollPlaza",
//     options: [
//       { label: "TollPlaza 1", value: "TollPlaza 1" },
//       { label: "TollPlaza 2", value: "TollPlaza 2" },
//       { label: "TollPlaza 3", value: "TollPlaza 3" },
//     ],
//   },
// ];

// const Header = (props) => {
//   const [search, setsearch] = useState(false);

//   const [selectedGroup, setselectedGroup] = useState(null);
//   function handleSelectGroup(selectedGroup) {
//     setselectedGroup(selectedGroup);
//   }

//   function toggleFullscreen() {
//     if (
//       !document.fullscreenElement &&
//       /* alternative standard method */ !document.mozFullScreenElement &&
//       !document.webkitFullscreenElement
//     ) {
//       // current working methods
//       if (document.documentElement.requestFullscreen) {
//         document.documentElement.requestFullscreen();
//       } else if (document.documentElement.mozRequestFullScreen) {
//         document.documentElement.mozRequestFullScreen();
//       } else if (document.documentElement.webkitRequestFullscreen) {
//         document.documentElement.webkitRequestFullscreen(
//           Element.ALLOW_KEYBOARD_INPUT
//         );
//       }
//     } else {
//       if (document.cancelFullScreen) {
//         document.cancelFullScreen();
//       } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen();
//       } else if (document.webkitCancelFullScreen) {
//         document.webkitCancelFullScreen();
//       }
//     }
//   }

//   function tToggle() {
//     /*
//       set logic for changing sidebar
//     */
//     if (document.body.clientWidth >= 993) {
//       //desktop view
//       if (props.leftSideBarType === "default") {
//         props.changeSidebarType("small");
//       } else if (
//         props.leftSideBarType === "small" ||
//         props.leftSideBarType === "compact"
//       ) {
//         props.changeSidebarType("default");
//       }
//     } else {
//       //mobile view
//       document.body.classList.toggle("sidebar-enable");
//       props.changeSidebarType("default");
//     }
//   }

//   return (
//     <React.Fragment>
//       <header id="page-topbar">
//         <div className="navbar-header">
//           <div className="d-flex">
//             <div className="navbar-brand-box">
//               <Link to="/" className="logo logo-dark">
//                 <span className="logo-sm">
//                   <img src={logoSm} alt="" height="22" />
//                 </span>
//                 <span className="logo-lg">
//                   <img src={logoDark} alt="" height="23" />
//                 </span>
//               </Link>

//               <Link to="/" className="logo logo-light">
//                 <span className="logo-sm">
//                   <img src={logoSmLight} alt="" height="22" />
//                 </span>
//                 <span className="logo-lg">
//                   <img src={logoLight} alt="" height="33" />
//                 </span>
//               </Link>
//             </div>
//             <button
//               type="button"
//               onClick={() => {
//                 tToggle();
//               }}
//               className="btn btn-sm px-3 font-size-16 header-item "
//               id="vertical-menu-btn"
//             >
//               <i className="fa fa-fw fa-bars" />
//             </button>

//             {/* <form className="app-search d-none d-lg-block">
//               <div className="position-relative">
//                 <input
//                   type="text"
//                   className="form-control"
//                   placeholder={props.t("Search") + "..."}
//                 />
//                 <span className="mdi mdi-magnify" />
//               </div>
//             </form> */}

//             <div className="d-flex gap-5  ">
//               <div className="mt-2">
//                 {/* <Label>Select</Label> */}
//                 <Select
//                   value={selectedGroup}
//                   onChange={() => {
//                     handleSelectGroup();
//                   }}
//                   id="SelectId"
//                   options={optionGroup}
//                   classNamePrefix="select2-selection"
//                 />
//               </div>

//               <div className="TopDivDateButton1 mt-1 ">
//                 <div className="col-auto " id="DateButton1">
//                   <DateRangeButton />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="d-flex">
//             <div className="dropdown d-inline-block d-lg-none ms-2">
//               <button
//                 onClick={() => {
//                   setsearch(!search);
//                 }}
//                 type="button"
//                 className="btn header-item noti-icon"
//                 id="page-header-search-dropdown"
//               >
//                 <i className="mdi mdi-magnify" />
//               </button>
//               <div
//                 className={
//                   search
//                     ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
//                     : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
//                 }
//                 aria-labelledby="page-header-search-dropdown"
//               >
//                 <form className="p-3">
//                   <div className="form-group m-0">
//                     <div className="input-group">
//                       <input
//                         type="text"
//                         className="form-control"
//                         placeholder="Search ..."
//                         aria-label="Recipient's username"
//                       />
//                       <button className="btn btn-primary" type="submit">
//                         <i className="mdi mdi-magnify" />
//                       </button>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>

//             <LanguageDropdown />

//             <div className="dropdown d-none d-lg-inline-block ms-1">
//               <button
//                 // 212529
//                 type="button"
//                 onClick={() => {
//                   toggleFullscreen();
//                 }}
//                 className="btn header-item noti-icon "
//                 data-toggle="fullscreen"
//               >
//                 <i className="mdi mdi-fullscreen" />
//               </button>
//             </div>

//             <NotificationDropdown />
//             <ProfileMenu />

//             <div
//               onClick={() => {
//                 props.showRightSidebarAction(!props.showRightSidebar);
//               }}
//               className="dropdown d-inline-block"
//             >
//               <button
//                 type="button"
//                 className="btn header-item noti-icon right-bar-toggle "
//               >
//                 <i className="bx bx-cog bx-spin" />
//               </button>
//             </div>
//           </div>
//         </div>
//       </header>
//     </React.Fragment>
//   );
// };

// Header.propTypes = {
//   changeSidebarType: PropTypes.any,
//   leftMenu: PropTypes.any,
//   leftSideBarType: PropTypes.any,
//   showRightSidebar: PropTypes.any,
//   showRightSidebarAction: PropTypes.any,
//   t: PropTypes.any,
//   toggleLeftmenu: PropTypes.any,
// };

// const mapStatetoProps = (state) => {
//   const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
//     state.Layout;
//   return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
// };

// export default connect(mapStatetoProps, {
//   showRightSidebarAction,
//   toggleLeftmenu,
//   changeSidebarType,
// })(withTranslation()(Header));







// import PropTypes from "prop-types";
// import React, { useState, useEffect } from "react";
// import { Label } from "reactstrap";
// import Select from "react-select";

// import { connect } from "react-redux";
// import { Link } from "react-router-dom";

// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import logoSm from "../../assets/images/logo-sm.png";
// import logoSmLight from "../../assets/images/logo-sm-light.png";
// import logoDark from "../../assets/images/logo-dark.png";
// import logoLight from "../../assets/images/logo-light.png";

// //i18n
// import { withTranslation } from "react-i18next";

// // Redux Store
// import {
//   showRightSidebarAction,
//   toggleLeftmenu,
//   changeSidebarType,
// } from "../../store/actions";
// import DateRangeButton from "../../pages/Dashboard/DateRangeButton";

// const Header = (props) => {
//   const [search, setsearch] = useState(false);
//   const [selectedGroup, setselectedGroup] = useState(null);
//   const [optionGroup, setOptionGroup] = useState([
//     { label: "All Traffic", options: [] },
//     { label: "TollPlaza", options: [] },
//   ]);

//   useEffect(() => {
//     // Fetch traffic data and update options
//     fetch("http://localhost:4001/tollplazas", { method: "GET" })
//       .then((response) => response.json())
//       .then((data) => {
//         const tollPlazaOptions = data.map((plaza) => ({
//           label: plaza.tollplaza_name,
//           value: plaza.tollplaza_id,
//         }));

//         setOptionGroup((prevOptions) => [
//           { label: "All Traffic", options: [] },
//           { label: "TollPlaza", options: tollPlazaOptions },
//         ]);
//       })
//       .catch((error) => console.error("Error fetching Plaza:", error));
//   }, []);

//   function handleSelectGroup(selectedOption) {
//     setselectedGroup(selectedOption);
//   }

//   function toggleFullscreen() {
//     if (
//       !document.fullscreenElement &&
//       /* alternative standard method */ !document.mozFullScreenElement &&
//       !document.webkitFullscreenElement
//     ) {
//       // current working methods
//       if (document.documentElement.requestFullscreen) {
//         document.documentElement.requestFullscreen();
//       } else if (document.documentElement.mozRequestFullScreen) {
//         document.documentElement.mozRequestFullScreen();
//       } else if (document.documentElement.webkitRequestFullscreen) {
//         document.documentElement.webkitRequestFullscreen(
//           Element.ALLOW_KEYBOARD_INPUT
//         );
//       }
//     } else {
//       if (document.cancelFullScreen) {
//         document.cancelFullScreen();
//       } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen();
//       } else if (document.webkitCancelFullScreen) {
//         document.webkitCancelFullScreen();
//       }
//     }
//   }

//   function tToggle() {
//     /*
//       set logic for changing sidebar
//     */
//     if (document.body.clientWidth >= 993) {
//       //desktop view
//       if (props.leftSideBarType === "default") {
//         props.changeSidebarType("small");
//       } else if (
//         props.leftSideBarType === "small" ||
//         props.leftSideBarType === "compact"
//       ) {
//         props.changeSidebarType("default");
//       }
//     } else {
//       //mobile view
//       document.body.classList.toggle("sidebar-enable");
//       props.changeSidebarType("default");
//     }
//   }

//   return (
//     <React.Fragment>
//       <header id="page-topbar">
//         <div className="navbar-header">
//           <div className="d-flex">
//             <div className="navbar-brand-box">
//               <Link to="/" className="logo logo-dark">
//                 <span className="logo-sm">
//                   <img src={logoSm} alt="" height="22" />
//                 </span>
//                 <span className="logo-lg">
//                   <img src={logoDark} alt="" height="23" />
//                 </span>
//               </Link>

//               <Link to="/" className="logo logo-light">
//                 <span className="logo-sm">
//                   <img src={logoSmLight} alt="" height="22" />
//                 </span>
//                 <span className="logo-lg">
//                   <img src={logoLight} alt="" height="33" />
//                 </span>
//               </Link>
//             </div>
//             <button
//               type="button"
//               onClick={() => {
//                 tToggle();
//               }}
//               className="btn btn-sm px-3 font-size-16 header-item "
//               id="vertical-menu-btn"
//             >
//               <i className="fa fa-fw fa-bars" />
//             </button>

//             <form className="app-search d-none d-lg-block">
//               <div className="position-relative">
//                 <input
//                   type="text"
//                   className="form-control"
//                   placeholder={props.t("Search") + "..."}
//                 />
//                 <span className="mdi mdi-magnify" />
//               </div>
//             </form>

//             <div className="d-flex gap-5  ">
//               <div className="mt-2">
//                 <Select
//                   value={selectedGroup}
//                   onChange={handleSelectGroup} // Updated handler
//                   id="SelectId"
//                   options={optionGroup}
//                   classNamePrefix="select2-selection"
//                 />
//               </div>

//               <div className="TopDivDateButton1 mt-1 ">
//                 <div className="col-auto " id="DateButton1">
//                   <DateRangeButton />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="d-flex">
//             <div className="dropdown d-inline-block d-lg-none ms-2">
//               <button
//                 onClick={() => {
//                   setsearch(!search);
//                 }}
//                 type="button"
//                 className="btn header-item noti-icon"
//                 id="page-header-search-dropdown"
//               >
//                 <i className="mdi mdi-magnify" />
//               </button>
//               <div
//                 className={
//                   search
//                     ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
//                     : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
//                 }
//                 aria-labelledby="page-header-search-dropdown"
//               >
//                 <form className="p-3">
//                   <div className="form-group m-0">
//                     <div className="input-group">
//                       <input
//                         type="text"
//                         className="form-control"
//                         placeholder="Search ..."
//                         aria-label="Recipient's username"
//                       />
//                       <button className="btn btn-primary" type="submit">
//                         <i className="mdi mdi-magnify" />
//                       </button>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>

//             <LanguageDropdown />

//             <div className="dropdown d-none d-lg-inline-block ms-1">
//               <button
//                 type="button"
//                 onClick={() => {
//                   toggleFullscreen();
//                 }}
//                 className="btn header-item noti-icon "
//                 data-toggle="fullscreen"
//               >
//                 <i className="mdi mdi-fullscreen" />
//               </button>
//             </div>

//             <NotificationDropdown />
//             <ProfileMenu />

//             <div
//               onClick={() => {
//                 props.showRightSidebarAction(!props.showRightSidebar);
//               }}
//               className="dropdown d-inline-block"
//             >
//               <button
//                 type="button"
//                 className="btn header-item noti-icon right-bar-toggle "
//               >
//                 <i className="bx bx-cog bx-spin" />
//               </button>
//             </div>
//           </div>
//         </div>
//       </header>
//     </React.Fragment>
//   );
// };

// Header.propTypes = {
//   changeSidebarType: PropTypes.func.isRequired,
//   leftMenu: PropTypes.any,
//   leftSideBarType: PropTypes.string.isRequired,
//   showRightSidebar: PropTypes.bool.isRequired,
//   showRightSidebarAction: PropTypes.func.isRequired,
//   t: PropTypes.func.isRequired,
//   toggleLeftmenu: PropTypes.func.isRequired,
// };

// const mapStatetoProps = (state) => {
//   const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
//     state.Layout;
//   return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
// };

// export default connect(mapStatetoProps, {
//   showRightSidebarAction,
//   toggleLeftmenu,
//   changeSidebarType,
// })(withTranslation()(Header));






























// import PropTypes from "prop-types";
// import React, { useState, useEffect } from "react";
// import Select from "react-select";
// import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
// import logoSm from "../../assets/images/logo-sm.png";
// import logoSmLight from "../../assets/images/logo-sm-light.png";
// import logoDark from "../../assets/images/logo-dark.png";
// import logoLight from "../../assets/images/logo-light.png";
// import { withTranslation } from "react-i18next";
// import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions";
// import DateRangeButton from "../../pages/Dashboard/DateRangeButton";

// const Header = (props) => {
//   const [search, setsearch] = useState(false);
//   const [selectedGroup, setselectedGroup] = useState(null);
//   const [optionGroup, setOptionGroup] = useState([
//     { label: "All Traffic", options: [] },
//     { label: "TollPlaza", options: [] },
//   ]);

//   useEffect(() => {
//     // Fetch traffic data and update options
//     fetch("http://localhost:4001/tollplazas", { method: "GET" })
//       .then((response) => response.json())
//       .then((data) => {
//         const tollPlazaOptions = data.map((plaza) => ({
//           label: plaza.tollplaza_name,
//           value: plaza.tollplaza_id,
//         }));

//         setOptionGroup((prevOptions) => [
//           { label: "All Traffic", options: [] },
//           { label: "TollPlaza", options: tollPlazaOptions },
//         ]);
//       })
//       .catch((error) => console.error("Error fetching TollPlaza:", error));
//   }, []);

//   function handleSelectGroup(selectedOption) {
//     setselectedGroup(selectedOption);
//   }

//   function toggleFullscreen() {
//     if (
//       !document.fullscreenElement &&
//       /* alternative standard method */ !document.mozFullScreenElement &&
//       !document.webkitFullscreenElement
//     ) {
//       // current working methods
//       if (document.documentElement.requestFullscreen) {
//         document.documentElement.requestFullscreen();
//       } else if (document.documentElement.mozRequestFullScreen) {
//         document.documentElement.mozRequestFullScreen();
//       } else if (document.documentElement.webkitRequestFullscreen) {
//         document.documentElement.webkitRequestFullscreen(
//           Element.ALLOW_KEYBOARD_INPUT
//         );
//       }
//     } else {
//       if (document.cancelFullScreen) {
//         document.cancelFullScreen();
//       } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen();
//       } else if (document.webkitCancelFullScreen) {
//         document.webkitCancelFullScreen();
//       }
//     }
//   }

//   function tToggle() {
//     if (document.body.clientWidth >= 993) {
//       //desktop view
//       if (props.leftSideBarType === "default") {
//         props.changeSidebarType("small");
//       } else if (
//         props.leftSideBarType === "small" ||
//         props.leftSideBarType === "compact"
//       ) {
//         props.changeSidebarType("default");
//       }
//     } else {
//       //mobile view
//       document.body.classList.toggle("sidebar-enable");
//       props.changeSidebarType("default");
//     }
//   }

//   return (
//     <React.Fragment>
//       <header id="page-topbar">
//         <div className="navbar-header">
//           <div className="d-flex">
//             <div className="navbar-brand-box">
//               <Link to="/" className="logo logo-dark">
//                 <span className="logo-sm">
//                   <img src={logoSm} alt="" height="22" />
//                 </span>
//                 <span className="logo-lg">
//                   <img src={logoDark} alt="" height="23" />
//                 </span>
//               </Link>

//               <Link to="/" className="logo logo-light">
//                 <span className="logo-sm">
//                   <img src={logoSmLight} alt="" height="22" />
//                 </span>
//                 <span className="logo-lg">
//                   <img src={logoLight} alt="" height="33" />
//                 </span>
//               </Link>
//             </div>
//             <button
//               type="button"
//               onClick={() => {
//                 tToggle();
//               }}
//               className="btn btn-sm px-3 font-size-16 header-item "
//               id="vertical-menu-btn"
//             >
//               <i className="fa fa-fw fa-bars" />
//             </button>

//             <div className="d-flex gap-5">
//               <div className="mt-2">
//                 <Select
//                   value={selectedGroup}
//                   onChange={handleSelectGroup} // Updated handler
//                   id="SelectId"
//                   options={optionGroup}
//                   classNamePrefix="select2-selection"
//                 />
//               </div>

//               <div className="TopDivDateButton1 mt-1">
//                 <div className="col-auto" id="DateButton1">
//                   <DateRangeButton />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="d-flex">
//             <div className="dropdown d-inline-block d-lg-none ms-2">
//               <button
//                 onClick={() => {
//                   setsearch(!search);
//                 }}
//                 type="button"
//                 className="btn header-item noti-icon"
//                 id="page-header-search-dropdown"
//               >
//                 <i className="mdi mdi-magnify" />
//               </button>
//               <div
//                 className={
//                   search
//                     ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
//                     : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
//                 }
//                 aria-labelledby="page-header-search-dropdown"
//               >
//                 <form className="p-3">
//                   <div className="form-group m-0">
//                     <div className="input-group">
//                       <input
//                         type="text"
//                         className="form-control"
//                         placeholder="Search ..."
//                         aria-label="Recipient's username"
//                       />
//                       <button className="btn btn-primary" type="submit">
//                         <i className="mdi mdi-magnify" />
//                       </button>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>

//             <LanguageDropdown />

//             <div className="dropdown d-none d-lg-inline-block ms-1">
//               <button
//                 type="button"
//                 onClick={() => {
//                   toggleFullscreen();
//                 }}
//                 className="btn header-item noti-icon"
//                 data-toggle="fullscreen"
//               >
//                 <i className="mdi mdi-fullscreen" />
//               </button>
//             </div>

//             <NotificationDropdown />
//             <ProfileMenu />

//             <div
//               onClick={() => {
//                 props.showRightSidebarAction(!props.showRightSidebar);
//               }}
//               className="dropdown d-inline-block"
//             >
//               <button
//                 type="button"
//                 className="btn header-item noti-icon right-bar-toggle"
//               >
//                 <i className="bx bx-cog bx-spin" />
//               </button>
//             </div>
//           </div>
//         </div>
//       </header>
//     </React.Fragment>
//   );
// };

// Header.propTypes = {
//   changeSidebarType: PropTypes.func.isRequired,
//   leftMenu: PropTypes.any,
//   leftSideBarType: PropTypes.string.isRequired,
//   showRightSidebar: PropTypes.bool.isRequired,
//   showRightSidebarAction: PropTypes.func.isRequired,
//   t: PropTypes.func.isRequired,
//   toggleLeftmenu: PropTypes.func.isRequired,
// };

// const mapStatetoProps = (state) => {
//   const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
//     state.Layout;
//   return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
// };

// export default connect(mapStatetoProps, {
//   showRightSidebarAction,
//   toggleLeftmenu,
//   changeSidebarType,
// })(withTranslation()(Header));



import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logoSm from "../../assets/images/logo-sm.png";
import logoSmLight from "../../assets/images/logo-sm-light.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import { withTranslation } from "react-i18next";
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions";
import DateRangeButton from "../../pages/Dashboard/DateRangeButton";

const Header = (props) => {
  const [search, setsearch] = useState(false);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [optionGroup, setOptionGroup] = useState([
    // { label: "All Traffic", options: [
      { label: "", options: [
      // { label: "Mustard", value: "Mustard" }, { label: "Ketchup", value: "Ketchup" }, { label: "Relish", value: "Relish" }
      // { label: "Mustard", value: "Mustard" }
      {label : "-- All Traffic --" , value : " -- All Traffic --"}
    ] },
    // { label: "TollPlaza", options: [] },
    { label: "-- All Traffic --", options: [
          
    ] },
  ]);

  useEffect(() => {
    // Fetch toll plaza data and update options
    fetch("https://trafficapp.applivity.com/api/tollplazas", { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        const tollPlazaOptions = data.map((plaza) => ({
          label: plaza.tollplaza_name,
          value: plaza.tollplaza_id,
        }));

        setOptionGroup((prevOptions) => [
          { ...prevOptions[0] }, // Keep "All Traffic" options unchanged
          // { label: "-- All Traffic --", options: tollPlazaOptions }, // Update "TollPlaza" options
          {  options: tollPlazaOptions },
        ]);
      })
      .catch((error) => console.error("Error fetching TollPlaza:", error));
  }, []);

  function handleSelectGroup(selectedOption) {
    setselectedGroup(selectedOption);
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    if (document.body.clientWidth >= 993) {
      //desktop view
      if (props.leftSideBarType === "default") {
        props.changeSidebarType("small");
      } else if (
        props.leftSideBarType === "small" ||
        props.leftSideBarType === "compact"
      ) {
        props.changeSidebarType("default");
      }
    } else {
      //mobile view
      document.body.classList.toggle("sidebar-enable");
      props.changeSidebarType("default");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="23" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSmLight} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="33" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <div className="d-flex gap-5">
              <div className="mt-2">
                <Select
                  value={selectedGroup}
                  onChange={handleSelectGroup}
                  id="SelectId"
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                />
              </div>

              <div className="TopDivDateButton1 mt-1">
                <div className="col-auto" id="DateButton1">
                  <DateRangeButton />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <LanguageDropdown />

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen" />
              </button>
            </div>

            <NotificationDropdown />
            <ProfileMenu />

            <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func.isRequired,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.string.isRequired,
  showRightSidebar: PropTypes.bool.isRequired,
  showRightSidebarAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleLeftmenu: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
