

// // DateRangeButton.js
// import React, { useState } from "react";
// import Calendar from "./Calendar"; // Adjust the path if necessary

// const DateRangeButton = () => {
//   const [showCalendar, setShowCalendar] = useState(false);
//   const [dateRangeState, setDateRangeState] = useState([
//     {
//       startDate: new Date(),
//       endDate: new Date(),
//       key: 'selection',
//     }
//   ]);

//   const handleOnChange = (ranges) => {
//     setDateRangeState([ranges.selection]);
//   };

//   return (
//     <div>
//       <div className="col-auto">

//       {/* <button class="btn btn-primary" type="button" id="inputGroupFileAddon03">Button</button> */}

//         <button
//           className="btn btn-primary "
//           type="button" id="inputGroupFileAddon03"
//           onClick={() => setShowCalendar(!showCalendar)}
//         >
//           {showCalendar ? "DateRange" : "DateRange"}
//         </button>
//       </div>

//       {showCalendar && (
//         <Calendar
//           handleOnChange={handleOnChange}
//           dateRangeState={dateRangeState}
//         />
//       )}
//     </div>
//   );
// };

// export default DateRangeButton;








// import React, { useState } from "react";
// import moment from "moment";
// import Calendar from "./Calendar"; // Adjust the path if necessary
// import '../Dashboard/Style/Style.css';

// const DateRangeButton = () => {
//   const [showCalendar, setShowCalendar] = useState(false);
//   const [dateRangeState, setDateRangeState] = useState([
//     {
//       startDate: new Date(),
//       endDate: new Date(),
//       key: 'selection',
//     }
//   ]);

//   // Date difference function defined inside the component
//   const getDateDifference = (dates) => {
//     const [startDate, endDate] = dates.map(date => moment(date).startOf('day'));
//     const today = moment().startOf('day');
//     const startOfWeek = today.clone().startOf('week');
//     const endOfWeek = today.clone().endOf('week');
//     const startOfMonth = today.clone().startOf('month');
//     const endOfMonth = today.clone().endOf('month');
//     const startOfLastWeek = startOfWeek.clone().subtract(1, 'week');
//     const endOfLastWeek = endOfWeek.clone().subtract(1, 'week');
//     const startOfLastMonth = startOfMonth.clone().subtract(1, 'month').startOf('month');
//     const endOfLastMonth = startOfMonth.clone().subtract(1, 'month').endOf('month');

//     // // Check for "Today"
//     // if (startDate.isSame(today, 'day') && endDate.isSame(today, 'day')) {
//     //   return "Today";
//     // }

//     // Check for "Today"
//     if (startDate.isSame(today, 'day') && endDate.isSame(today, 'day')) {
//       return "Today";
//     }


//     // // Check for "Yesterday"
//     // if (startDate.isSame(today.clone().subtract(1, 'day'), 'day') && endDate.isSame(today, 'day')) {
//     //   return "Yesterday";
//     // }

//     // Check for "Yesterday"
//     if (startDate.isSame(today.subtract(1, 'day'), 'day') && endDate.isSame(today, 'day')) {
//       return "Yesterday";
//     }

    
//     // Reset today to current date for subsequent checks
//     today.add(1, 'day');

//     // Check for "This Week"
//     if (startDate.isSame(startOfWeek, 'day') && endDate.isSame(endOfWeek, 'day')) {
//       return "This Week";
//     }

//     // Check for "Last Week"
//     if (startDate.isSame(startOfLastWeek, 'day') && endDate.isSame(endOfLastWeek, 'day')) {
//       return "Last Week";
//     }

//     // Check for "This Month"
//     if (startDate.isSame(startOfMonth, 'day') && endDate.isSame(endOfMonth, 'day')) {
//       return "This Month";
//     }

//     // Check for "Last Month"
//     if (startDate.isSame(startOfLastMonth, 'day') && endDate.isSame(endOfLastMonth, 'day')) {
//       return "Last Month";
//     }

//     // For custom date ranges
//       return `Custom (${startDate.format('MMM D')} - ${endDate.format('MMM D')})`;
//     // return "Custom";
//   };

//   const handleOnChange = (ranges) => {
//     setDateRangeState([ranges.selection]);
//   };

//   const { startDate, endDate } = dateRangeState[0];
//   const buttonText = getDateDifference([startDate, endDate]);

//   return (
//     <div style={{ marginLeft: "30px" }}>
//       <div className="col-auto">
//         <button
//           className="btn btn-primary btn-lg"
//           onClick={() => setShowCalendar(!showCalendar)}
//         >
//           {showCalendar ? ` ${buttonText}` : `${buttonText}`}
//         </button>
//       </div>

//       {showCalendar && (
//         <Calendar
//           handleOnChange={handleOnChange}
//           dateRangeState={dateRangeState}
//         />
//       )}
//     </div>
//   );
// };

// export default DateRangeButton;













// import React, { useState, useRef, useEffect } from "react";
// import '../Dashboard/Style/Style.css'
// import moment from "moment";
// import Calendar from "./Calendar"; // Adjust the path if necessary
// import { IoMdArrowDropdown } from "react-icons/io";

// const DateRangeButton = () => {
//   const [showCalendar, setShowCalendar] = useState(false);
//   const [dateRangeState, setDateRangeState] = useState([
//     {
//       startDate: new Date(),
//       endDate: new Date(),
//       key: 'selection',
//     }
//   ]);

//   const calendarRef = useRef(null);
//   const buttonRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         calendarRef.current && !calendarRef.current.contains(event.target) &&
//         buttonRef.current && !buttonRef.current.contains(event.target)
//       ) {
//         setShowCalendar(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, []);

//   // Updated date difference function
//   const getDateDifference = (dates) => {
//     const [startDate, endDate] = dates.map(date => moment(date).startOf('day'));
//     const today = moment().startOf('day');
//     const yesterday = today.clone().subtract(1, 'day');
//     const startOfWeek = today.clone().startOf('week');
//     const endOfWeek = today.clone().endOf('week');
//     const startOfMonth = today.clone().startOf('month');
//     const endOfMonth = today.clone().endOf('month');
//     const startOfLastWeek = startOfWeek.clone().subtract(1, 'week');
//     const endOfLastWeek = endOfWeek.clone().subtract(1, 'week');
//     const startOfLastMonth = startOfMonth.clone().subtract(1, 'month').startOf('month');
//     const endOfLastMonth = startOfMonth.clone().subtract(1, 'month').endOf('month');

//     // Check for "Today" (strict equality on date)
//     if (startDate.isSame(today, 'day') && endDate.isSame(today, 'day')) {
//       return `Today ${today.format('D MMM YYYY')}`;
//     }

//     // Check for "Yesterday"
//     if (startDate.isSame(yesterday, 'day') && endDate.isSame(yesterday, 'day')) {
//       return `Yesterday ${yesterday.format('D MMM YYYY')}`;
//     }

//     // Check for "This Week"
//     if (startDate.isSame(startOfWeek, 'day') && endDate.isSame(endOfWeek, 'day')) {
//       return `This Week ${startOfWeek.format('D MMM')} - ${endOfWeek.format('D MMM YYYY')}`;
//     }

//     // Check for "Last Week"
//     if (startDate.isSame(startOfLastWeek, 'day') && endDate.isSame(endOfLastWeek, 'day')) {
//       return `Last Week ${startOfLastWeek.format('D MMM')} - ${endOfLastWeek.format('D MMM YYYY')}`;
//     }

//     // Check for "This Month"
//     if (startDate.isSame(startOfMonth, 'day') && endDate.isSame(endOfMonth, 'day')) {
//       return `This Month ${startOfMonth.format('D MMM')} - ${endOfMonth.format('D MMM YYYY')}`;
//     }

//     // Check for "Last Month"
//     if (startDate.isSame(startOfLastMonth, 'day') && endDate.isSame(endOfLastMonth, 'day')) {
//       return `Last Month ${startOfLastMonth.format('D MMM')} - ${endOfLastMonth.format('D MMM YYYY')}`;
//     }

//     // If the selected range includes only one day and it's not today
//     if (startDate.isSame(endDate, 'day') && !startDate.isSame(today, 'day')) {
//       return `Custom ${startDate.format('D MMM YYYY')}`;
//     }

//     // Custom range for multiple days
//     return `Custom ${startDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
//   };

//   // Handle date range change
//   const handleOnChange = (ranges) => {
//     setDateRangeState([ranges.selection]);
//   };

//   // Get the start and end date from the state
//   const { startDate, endDate } = dateRangeState[0];

//   // Get button text based on selected date range
//   const buttonText = getDateDifference([startDate, endDate]);

//   return (
//     <div style={{ marginLeft: "30px" }}>
//       <div className="col-auto">
//         <button
//           className="btn btn-primary btn-lg d-flex align-items-center btn-custom"
//           onClick={() => setShowCalendar(prev => !prev)}
//           ref={buttonRef}
//         >
//           <span className="btn-custom button-text-style">{buttonText}</span>
//           <IoMdArrowDropdown 
//             style={{ marginLeft: '8px', color: 'white' }} 
//           />
//         </button>
//       </div>

//       {showCalendar && (
//         <div ref={calendarRef}>
//           <Calendar
//             handleOnChange={handleOnChange}
//             dateRangeState={dateRangeState}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default DateRangeButton;







// import React, { useState, useRef, useEffect } from "react";
// import '../Dashboard/Style/Style.css'
// import moment from "moment";
// import Calendar from "./Calendar"; // Adjust the path if necessary
// import { IoMdArrowDropdown } from "react-icons/io";

// const DateRangeButton = () => {
//   const [showCalendar, setShowCalendar] = useState(false);
//   const [dateRangeState, setDateRangeState] = useState([
//     {
//       startDate: new Date(),
//       endDate: new Date(),
//       key: 'selection',
//     }
//   ]);

//   const calendarRef = useRef(null);
//   const buttonRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         calendarRef.current && !calendarRef.current.contains(event.target) &&
//         buttonRef.current && !buttonRef.current.contains(event.target)
//       ) {
//         setShowCalendar(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, []);

//   const getDateDifference = (dates) => {
//     const [startDate, endDate] = dates.map(date => moment(date).startOf('day'));
//     const today = moment().startOf('day');
//     const yesterday = today.clone().subtract(1, 'day');
//     const startOfWeek = today.clone().startOf('week');
//     const endOfWeek = today.clone().endOf('week');
//     const startOfMonth = today.clone().startOf('month');
//     const endOfMonth = today.clone().endOf('month');
//     const startOfLastWeek = startOfWeek.clone().subtract(1, 'week');
//     const endOfLastWeek = endOfWeek.clone().subtract(1, 'week');
//     const startOfLastMonth = startOfMonth.clone().subtract(1, 'month').startOf('month');
//     const endOfLastMonth = startOfMonth.clone().subtract(1, 'month').endOf('month');

//     if (startDate.isSame(today, 'day') && endDate.isSame(today, 'day')) {
//       return `Today ${today.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(yesterday, 'day') && endDate.isSame(yesterday, 'day')) {
//       return `Yesterday ${yesterday.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(startOfWeek, 'day') && endDate.isSame(endOfWeek, 'day')) {
//       return `This Week ${startOfWeek.format('D MMM')} - ${endOfWeek.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(startOfLastWeek, 'day') && endDate.isSame(endOfLastWeek, 'day')) {
//       return `Last Week ${startOfLastWeek.format('D MMM')} - ${endOfLastWeek.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(startOfMonth, 'day') && endDate.isSame(endOfMonth, 'day')) {
//       return `This Month ${startOfMonth.format('D MMM')} - ${endOfMonth.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(startOfLastMonth, 'day') && endDate.isSame(endOfLastMonth, 'day')) {
//       return `Last Month ${startOfLastMonth.format('D MMM')} - ${endOfLastMonth.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(endDate, 'day') && !startDate.isSame(today, 'day')) {
//       return `Custom ${startDate.format('D MMM YYYY')}`;
//     }

//     return `Custom ${startDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
//   };

//   const handleOnChange = (ranges) => {
//     setDateRangeState([ranges.selection]);
//   };

//   const { startDate, endDate } = dateRangeState[0];

//   const buttonText = getDateDifference([startDate, endDate]);

//   const label = buttonText.split(" ").slice(0, 2).join(" "); // Extracts labels like "This Week", "Last Month"
//   const dates = buttonText.split(" ").slice(2).join(" "); // Extracts the date part

//   return (
//     <div style={{ marginLeft: "30px" }}>
//       <div className="col-auto">
//         <button
//           className="btn btn-primary btn-lg d-flex align-items-center btn-custom"
//           onClick={() => setShowCalendar(prev => !prev)}
//           ref={buttonRef}
//         >
//           {/* Style applied to full label part */}
//           <span
//             className="btn-custom"
//             style={{
//               backgroundColor: "white",
//               color: "black",
//               padding: "5px 10px",
//               borderRadius: "5px",
//             }}
//           >
//             {label}
//           </span>
//           &nbsp;
//           {/* Dates part remains without the special styling */}
//           <span className="btn-custom">{dates}</span>
//           <IoMdArrowDropdown 
//             style={{ marginLeft: '8px', color: 'white' }} 
//           />
//         </button>
//       </div>

//       {showCalendar && (
//         <div ref={calendarRef}>
//           <Calendar
//             handleOnChange={handleOnChange}
//             dateRangeState={dateRangeState}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default DateRangeButton;




























// import React, { useState, useRef, useEffect } from "react";
// import '../Dashboard/Style/Style.css';
// import moment from "moment";
// import Calendar from "./Calendar"; // Adjust the path if necessary
// import { IoMdArrowDropdown } from "react-icons/io";

// const DateRangeButton = () => {
//   const [showCalendar, setShowCalendar] = useState(false);
//   const [dateRangeState, setDateRangeState] = useState([
//     {
//       startDate: new Date(),
//       endDate: new Date(),
//       key: 'selection',
//     }
//   ]);

//   const calendarRef = useRef(null);
//   const buttonRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         calendarRef.current && !calendarRef.current.contains(event.target) &&
//         buttonRef.current && !buttonRef.current.contains(event.target)
//       ) {
//         setShowCalendar(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, []);

//   const getDateDifference = (dates) => {
//     const [startDate, endDate] = dates.map(date => moment(date).startOf('day'));
//     const today = moment().startOf('day');
//     const yesterday = today.clone().subtract(1, 'day');
//     const startOfWeek = today.clone().startOf('week');
//     const endOfWeek = today.clone().endOf('week');
//     const startOfMonth = today.clone().startOf('month');
//     const endOfMonth = today.clone().endOf('month');
//     const startOfLastWeek = startOfWeek.clone().subtract(1, 'week');
//     const endOfLastWeek = endOfWeek.clone().subtract(1, 'week');
//     const startOfLastMonth = startOfMonth.clone().subtract(1, 'month').startOf('month');
//     const endOfLastMonth = startOfMonth.clone().subtract(1, 'month').endOf('month');

//     if (startDate.isSame(today, 'day') && endDate.isSame(today, 'day')) {
//       return `Today ${today.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(yesterday, 'day') && endDate.isSame(yesterday, 'day')) {
//       return `Yesterday ${yesterday.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(startOfWeek, 'day') && endDate.isSame(endOfWeek, 'day')) {
//       return `This Week ${startOfWeek.format('D MMM')} - ${endOfWeek.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(startOfLastWeek, 'day') && endDate.isSame(endOfLastWeek, 'day')) {
//       return `Last Week ${startOfLastWeek.format('D MMM')} - ${endOfLastWeek.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(startOfMonth, 'day') && endDate.isSame(endOfMonth, 'day')) {
//       return `This Month ${startOfMonth.format('D MMM')} - ${endOfMonth.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(startOfLastMonth, 'day') && endDate.isSame(endOfLastMonth, 'day')) {
//       return `Last Month ${startOfLastMonth.format('D MMM')} - ${endOfLastMonth.format('D MMM YYYY')}`;
//     }

//     if (startDate.isSame(endDate, 'day') && !startDate.isSame(today, 'day')) {
//       return `Custom ${startDate.format('D MMM YYYY')}`;
//     }

//     return `Custom ${startDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
//   };

//   const handleOnChange = (ranges) => {
//     setDateRangeState([ranges.selection]);
//   };

//   const { startDate, endDate } = dateRangeState[0];

//   const buttonText = getDateDifference([startDate, endDate]);

//   const label = buttonText.split(" ").slice(0, 2).join(" "); // Extracts labels like "This Week", "Last Month"
//   const dates = buttonText.split(" ").slice(2).join(" "); // Extracts the date part

//   return (
//     <div className="btn-primary-custom">
//       <div className="col-auto">
//         <button
//           className="btn btn-primary btn-lg d-flex align-items-center"
//           onClick={() => setShowCalendar(prev => !prev)}
//           ref={buttonRef}
//         >
//           {/* Style applied to full label part */}
//           <span className="btn-custom-label">
//             {label}
//           </span>
//           &nbsp;
//           {/* Dates part */}
//           <span className="btn-custom-date">{dates}</span>
//           <IoMdArrowDropdown 
//             className="btn-custom-dropdown" 
//           />
//         </button>
//       </div>

//       {showCalendar && (
//         <div ref={calendarRef}>
//           <Calendar
//             handleOnChange={handleOnChange}
//             dateRangeState={dateRangeState}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default DateRangeButton;







import React, { useState, useRef, useEffect } from "react";
import '../Dashboard/Style/Style.css';
import moment from "moment";
import Calendar from "./Calendar"; // Adjust the path if necessary
import { IoMdArrowDropdown } from "react-icons/io";

const DateRangeButton = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }
  ]);

  const calendarRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef.current && !calendarRef.current.contains(event.target) &&
        buttonRef.current && !buttonRef.current.contains(event.target)
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const getDateDifference = (dates) => {
    const [startDate, endDate] = dates.map(date => moment(date).startOf('day'));
    const today = moment().startOf('day');
    const yesterday = today.clone().subtract(1, 'day');
    const startOfWeek = today.clone().startOf('week');
    const endOfWeek = today.clone().endOf('week');
    const startOfMonth = today.clone().startOf('month');
    const endOfMonth = today.clone().endOf('month');
    const startOfLastWeek = startOfWeek.clone().subtract(1, 'week');
    const endOfLastWeek = endOfWeek.clone().subtract(1, 'week');
    const startOfLastMonth = startOfMonth.clone().subtract(1, 'month').startOf('month');
    const endOfLastMonth = startOfMonth.clone().subtract(1, 'month').endOf('month');

    if (startDate.isSame(today, 'day') && endDate.isSame(today, 'day')) {
      return `Today ${today.format('D MMM YYYY')}`;
    }

    if (startDate.isSame(yesterday, 'day') && endDate.isSame(yesterday, 'day')) {
      return `Yesterday ${yesterday.format('D MMM YYYY')}`;
    }

    if (startDate.isSame(startOfWeek, 'day') && endDate.isSame(endOfWeek, 'day')) {
      return `This Week ${startOfWeek.format('D MMM')} - ${endOfWeek.format('D MMM YYYY')}`;
    }

    if (startDate.isSame(startOfLastWeek, 'day') && endDate.isSame(endOfLastWeek, 'day')) {
      return `Last Week ${startOfLastWeek.format('D MMM')} - ${endOfLastWeek.format('D MMM YYYY')}`;
    }

    if (startDate.isSame(startOfMonth, 'day') && endDate.isSame(endOfMonth, 'day')) {
      return `This Month ${startOfMonth.format('D MMM')} - ${endOfMonth.format('D MMM YYYY')}`;
    }

    if (startDate.isSame(startOfLastMonth, 'day') && endDate.isSame(endOfLastMonth, 'day')) {
      return `Last Month ${startOfLastMonth.format('D MMM')} - ${endOfLastMonth.format('D MMM YYYY')}`;
    }

    if (startDate.isSame(endDate, 'day') && !startDate.isSame(today, 'day')) {
      return `Custom ${startDate.format('D MMM YYYY')}`;
    }

    return `Custom ${startDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
  };

  const handleOnChange = (ranges) => {
    setDateRangeState([ranges.selection]);
  };

  const { startDate, endDate } = dateRangeState[0];
  const buttonText = getDateDifference([startDate, endDate]);

  // Extract label and dates
  const isToday = buttonText.startsWith('Today');
  const isYesterday = buttonText.startsWith('Yesterday');
  const isCustom = buttonText.startsWith('Custom');
  const label = isToday ? 'Today' : 
                 isYesterday ? 'Yesterday' : 
                 isCustom ? 'Custom' : 
                 buttonText.split(" ").slice(0, 2).join(" "); // For other ranges

  const dates = isToday || isYesterday || isCustom ? buttonText.split(" ").slice(1).join(" ") :
                 buttonText.split(" ").slice(2).join(" "); // For other ranges

  // Determine label style based on the label
  const getLabelStyle = () => {
    if (isToday || isYesterday || isCustom) {
      return { backgroundColor: "#f56e50", color: "white" }; // White background for Today, Yesterday, and Custom
    }
    return { backgroundColor: "#f56e50", color: "white" }; // Default background color
  };

  return (
    <div className="btn-primary-custom">
      <div className="col-auto">
        <button
          className="btn  d-flex align-items-center " id="Okas"
          onClick={() => setShowCalendar(prev => !prev)}
          ref={buttonRef}
        >
          <span
            className="btn-custom-label" id="Oka"
            style={getLabelStyle()}
          >
            {label}
          </span>
          &nbsp; &nbsp;
          <span className="btn-custom-date" id="Oka">{dates}</span>
          <IoMdArrowDropdown 
            className="btn-custom-dropdown" 
            style={{ marginLeft: 'auto' }}
          />
        </button>
      </div>

      {showCalendar && (
        <div ref={calendarRef}>
          <Calendar
            handleOnChange={handleOnChange}
            dateRangeState={dateRangeState}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangeButton;







