// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';

// import { FaCar, FaBus, FaTruck, FaMotorcycle,FaQuestionCircle, FaShuttleVan} from 'react-icons/fa';

// // FaTruckLoading, FaVan, FaBicycle, FaQuestionCircle
// const TotalVehicleCount = () => {
//     const VehicleCountData = [
//         { Type: 'Car', VehicleCount: 1000, LHRTOISB: 450, ISBTOLHR: 450 },
//         { Type: 'Bus', VehicleCount: 1020, LHRTOISB: 570, ISBTOLHR: 450 },
//         { Type: 'Van', VehicleCount: 980, LHRTOISB: 640, ISBTOLHR: 340 },
//         { Type: 'Trucks', VehicleCount: 1040, LHRTOISB: 320, ISBTOLHR: 350 },
//         { Type: 'Motorcycle', VehicleCount: 990, LHRTOISB: 660, ISBTOLHR: 330 },
//         { Type: 'Car', VehicleCount: 1010, LHRTOISB: 680, ISBTOLHR: 330 },
//         { Type: 'Van', VehicleCount: 980, LHRTOISB: 640, ISBTOLHR: 340 },
//         { Type: 'Trucks', VehicleCount: 1040, LHRTOISB: 690, ISBTOLHR: 35},
//         { Type: 'Car', VehicleCount: 1015, LHRTOISB: 690, ISBTOLHR: 325 },
//         { Type: 'Bus', VehicleCount: 1020, LHRTOISB: 670, ISBTOLHR: 450 },
//     ];

//     // Define a mapping of vehicle types to icons
//     const iconMap = {
//         'Car': <FaCar className="text-primary" size={20} />,
//         'Bus': <FaBus className="text-warning" size={20} />,
//         'Van': <FaShuttleVan className="text-success" size={20} />,
//         'Trucks': <FaTruck className="text-danger" size={20} />, // Use FaTruckLoading for Trucks
//         'Motorcycle': <FaMotorcycle className="text-info" size={20} />,
//         // 'Bicycle': <FaBicycle className="text-secondary" size={20} /> // Example for a new type
//     };

//     return (
//         <>
//             <div className="mb-4"><h3>Total Vehicle Count</h3></div>
//             <table className="table table-striped table-bordered">
//                 <thead>
//                     <tr>
//                         <th>Type</th>
//                         <th>Vehicle Count</th>
//                         <th>LHR-ISB</th>
//                         <th>ISB-LHR</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {VehicleCountData.map((Count, index) => (
//                         <tr key={index}>
//                             <td>
//                                 <span className='d-flex align-items-center'>
//                                     {iconMap[Count.Type] || <FaQuestionCircle className="text-muted" size={20} />}
//                                     <span className="ms-2">{Count.Type}</span>
//                                 </span>
//                             </td>
//                             <td>{Count.VehicleCount}</td>
//                             <td>{Count.LHRTOISB}</td>
//                             <td>{Count.ISBTOLHR}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </>
//     );
// };

// export default TotalVehicleCount;



// import React from 'react';
// import { Card, CardBody, CardTitle } from 'reactstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// import { FaCar, FaBus, FaTruck, FaMotorcycle, FaQuestionCircle, FaShuttleVan } from 'react-icons/fa';

// const TotalVehicleCount = () => {
//     const VehicleCountData = [
//         { Type: 'Car', VehicleCount: 1000, LHRTOISB: 450, ISBTOLHR: 450 },
//         { Type: 'Bus', VehicleCount: 1020, LHRTOISB: 570, ISBTOLHR: 450 },
//         { Type: 'Van', VehicleCount: 980, LHRTOISB: 640, ISBTOLHR: 340 },
//         { Type: 'Trucks', VehicleCount: 1040, LHRTOISB: 320, ISBTOLHR: 350 },
//         { Type: 'Motorcycle', VehicleCount: 990, LHRTOISB: 660, ISBTOLHR: 330 },
//         { Type: 'Car', VehicleCount: 1010, LHRTOISB: 680, ISBTOLHR: 330 },
//         { Type: 'Van', VehicleCount: 980, LHRTOISB: 640, ISBTOLHR: 340 },
//         { Type: 'Trucks', VehicleCount: 1040, LHRTOISB: 690, ISBTOLHR: 35 },
//         { Type: 'Car', VehicleCount: 1015, LHRTOISB: 690, ISBTOLHR: 325 },
//         { Type: 'Bus', VehicleCount: 1020, LHRTOISB: 670, ISBTOLHR: 450 },
//     ];

//     // Define a mapping of vehicle types to icons
//     const iconMap = {
//         'Car': <FaCar className="text-primary" size={20} />,
//         'Bus': <FaBus className="text-warning" size={20} />,
//         'Van': <FaShuttleVan className="text-success" size={20} />,
//         'Trucks': <FaTruck className="text-danger" size={20} />,
//         'Motorcycle': <FaMotorcycle className="text-info" size={20} />,
//         // 'Bicycle': <FaBicycle className="text-secondary" size={20} /> // Example for a new type
//     };

//     return (
//         <Card>
//             <CardBody>
//                 <CardTitle tag="h3" className="mb-4">Total Vehicle Count</CardTitle>
//                 <div className="table-responsive">
//                     <table className="table table-striped table-bordered mb-0">
//                         <thead>
//                             <tr>
//                                 <th>Type</th>
//                                 <th>Vehicle Count</th>
//                                 <th>LHR-ISB</th>
//                                 <th>ISB-LHR</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {VehicleCountData.map((Count, index) => (
//                                 <tr key={index}>
//                                     <td>
//                                         <span className='d-flex align-items-center'>
//                                             {iconMap[Count.Type] || <FaQuestionCircle className="text-muted" size={20} />}
//                                             <span className="ms-2">{Count.Type}</span>
//                                         </span>
//                                     </td>
//                                     <td>{Count.VehicleCount}</td>
//                                     <td>{Count.LHRTOISB}</td>
//                                     <td>{Count.ISBTOLHR}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </CardBody>
//         </Card>
//     );
// };

// export default TotalVehicleCount;




import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../Dashboard/Style/Style.css';
import { FaCar, FaBus, FaTruck, FaMotorcycle, FaQuestionCircle, FaShuttleVan } from 'react-icons/fa';

const TotalVehicleCount = () => {
    const VehicleCountData = [
        { Type: 'Car', VehicleCount: 32400, LHRTOISB: 6000, ISBTOLHR: 45000 },
        { Type: 'Bus', VehicleCount: 1020, LHRTOISB: 570, ISBTOLHR: 450 },
        { Type: 'Van', VehicleCount: 980, LHRTOISB: 640, ISBTOLHR: 340 },
        { Type: 'Trucks', VehicleCount: 1040, LHRTOISB: 320, ISBTOLHR: 350 },
        { Type: 'Motorcycle', VehicleCount: 990, LHRTOISB: 660, ISBTOLHR: 330 },
        { Type: 'Car', VehicleCount: 1010, LHRTOISB: 680, ISBTOLHR: 330 },
        { Type: 'Van', VehicleCount: 980, LHRTOISB: 640, ISBTOLHR: 340 },
        { Type: 'Trucks', VehicleCount: 1040, LHRTOISB: 690, ISBTOLHR: 35 },
        { Type: 'Car', VehicleCount: 1000, LHRTOISB: 1690, ISBTOLHR: 325 },
        { Type: 'Bus', VehicleCount: 1020, LHRTOISB: 670, ISBTOLHR: 450 },
    ];

    // Aggregate data
    const aggregatedData = VehicleCountData.reduce((acc, curr) => {
        if (!acc[curr.Type]) {
            acc[curr.Type] = { ...curr };
        } else {
            acc[curr.Type].VehicleCount += curr.VehicleCount;
            acc[curr.Type].LHRTOISB += curr.LHRTOISB;
            acc[curr.Type].ISBTOLHR += curr.ISBTOLHR;
        }
        return acc;
    }, {});

    const aggregatedArray = Object.values(aggregatedData);

    // Define a mapping of vehicle types to icons
    const iconMap = {
        'Car': <FaCar className="text-primary" size={20} />,
        'Bus': <FaBus className="text-warning" size={20} />,
        'Van': <FaShuttleVan className="text-success" size={20} />,
        'Trucks': <FaTruck className="text-danger" size={20} />,
        'Motorcycle': <FaMotorcycle className="text-info" size={20} />,
        // 'Bicycle': <FaBicycle className="text-secondary" size={20} /> // Example for a new type
    };

    return (
        <Card>
            <CardBody>
                <CardTitle tag="h3" className="mb-4">Total Vehicle Count</CardTitle>
                <div className="table-responsive">
                    <table className="table table-striped table-custom table-bordered mb-0">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Vehicle Count</th>
                                <th>LHR-ISB</th>
                                <th>ISB-LHR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {aggregatedArray.map((Count, index) => (
                                <tr key={index}>
                                    <td>
                                        <span className='d-flex align-items-center'>
                                            {iconMap[Count.Type] || <FaQuestionCircle className="text-muted" size={20} />}
                                            <span className="ms-2">{Count.Type}</span>
                                        </span>
                                    </td>
                                    <td>{Count.VehicleCount}</td>
                                    <td>{Count.LHRTOISB}</td>
                                    <td>{Count.ISBTOLHR}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};

export default TotalVehicleCount;


