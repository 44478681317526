// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// import { Col ,CardTitle} from 'reactstrap';
// // import { HiOutlinePencilSquare } from "react-icons/hi2";
// // import { MdDelete } from "react-icons/md";

// const AllTollPlaza = () => {
//     const AllTollPlazaData = [
//         { TollPlaza: 'TollPlaza 1', TotalVehicle: 1200, LHRTOISB: 350, ISBTOLHR: 250 },
//         { TollPlaza: 'TollPlaza 2', TotalVehicle: 1120, LHRTOISB: 470, ISBTOLHR: 250 },
//         { TollPlaza: 'TollPlaza 3', TotalVehicle: 620, LHRTOISB: 660, ISBTOLHR: 340 },
//         { TollPlaza: 'TollPlaza 4', TotalVehicle: 1040, LHRTOISB: 490, ISBTOLHR: 350 },
//         { TollPlaza: 'TollPlaza 5', TotalVehicle: 1190, LHRTOISB: 560, ISBTOLHR: 330 },
//         { TollPlaza: 'TollPlaza 6', TotalVehicle: 510, LHRTOISB: 180, ISBTOLHR: 330 },
//         { TollPlaza: 'TollPlaza 7', TotalVehicle: 980, LHRTOISB: 1250, ISBTOLHR: 330 },
//         { TollPlaza: 'TollPlaza 8', TotalVehicle: 305, LHRTOISB: 610, ISBTOLHR: 335 },
//         { TollPlaza: 'TollPlaza 9', TotalVehicle: 990, LHRTOISB: 610, ISBTOLHR: 330 },
//         {  TollPlaza: 'TollPlaza 10', TotalVehicle: 315, LHRTOISB: 190, ISBTOLHR: 325 }
//     ];

//     return (
//         <>
//           <Col xl={4}>
//           <CardTitle className="mb-4">All TollPlaza</CardTitle>
         
//             <table className="table table-striped table-bordered">
//                 <thead>
//                     <tr>
//                         {/* <th>Id#</th> */}
//                         <th>Toll Plaza</th>
//                         <th>Total Vehicle</th>
//                         <th>LHR-ISB</th>
//                         <th>ISB-LHR</th>
                    
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {AllTollPlazaData.map((TollPlaza, index) => (
//                         <tr key={index}>
//                             {/* <td>{TollPlaza.ID}</td> */}
//                             <td>{TollPlaza.TollPlaza}</td>
//                             <td>{TollPlaza.TotalVehicle}</td>
//                             <td>{TollPlaza.LHRTOISB}</td>
//                             <td>{TollPlaza.ISBTOLHR}</td>
//                             {/* <td>
//                       <button
//                     className="btn btn-success btn-sm me-2" >
//                       <HiOutlinePencilSquare />
//                     </button>
//                      <button
//                     className="btn btn-danger btn-sm"
//                     >
//                          <MdDelete   />
//                     </button>
//                 </td> */}
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//           </Col>
       
//         </>
//     );
// };

// export default AllTollPlaza;




import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../Dashboard/Style/Style.css';
// import { HiOutlinePencilSquare } from "react-icons/hi2";
// import { MdDelete } from "react-icons/md";

const AllTollPlaza = () => {
    const AllTollPlazaData = [
        { TollPlaza: 'TollPlaza 1', TotalVehicle: 1200, LHRTOISB: 350, ISBTOLHR: 250 },
        { TollPlaza: 'TollPlaza 2', TotalVehicle: 1120, LHRTOISB: 470, ISBTOLHR: 250 },
        { TollPlaza: 'TollPlaza 3', TotalVehicle: 620, LHRTOISB: 660, ISBTOLHR: 340 },
        { TollPlaza: 'TollPlaza 4', TotalVehicle: 1040, LHRTOISB: 490, ISBTOLHR: 350 },
        { TollPlaza: 'TollPlaza 5', TotalVehicle: 1190, LHRTOISB: 560, ISBTOLHR: 330 },
        { TollPlaza: 'TollPlaza 6', TotalVehicle: 510, LHRTOISB: 180, ISBTOLHR: 330 },
        { TollPlaza: 'TollPlaza 7', TotalVehicle: 980, LHRTOISB: 1250, ISBTOLHR: 330 },
        { TollPlaza: 'TollPlaza 8', TotalVehicle: 305, LHRTOISB: 610, ISBTOLHR: 335 },
        { TollPlaza: 'TollPlaza 9', TotalVehicle: 990, LHRTOISB: 610, ISBTOLHR: 330 },
        { TollPlaza: 'TollPlaza 10', TotalVehicle: 315, LHRTOISB: 190, ISBTOLHR: 325 }
    ];

    return (
        <Card>
            <CardBody>
                <CardTitle tag="h3" className="mb-4">All TollPlaza</CardTitle>
                <div className="table-responsive">
                    <table className="table table-striped table-custom table-bordered mb-0">
                        <thead>
                            <tr>
                                {/* <th>Id#</th> */}
                                <th>Toll Plaza</th>
                                <th>Total Vehicle</th>
                                <th>LHR-ISB</th>
                                <th>ISB-LHR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {AllTollPlazaData.map((TollPlaza, index) => (
                                <tr key={index}>
                                    {/* <td>{TollPlaza.ID}</td> */}
                                    <td>{TollPlaza.TollPlaza}</td>
                                    <td>{TollPlaza.TotalVehicle}</td>
                                    <td>{TollPlaza.LHRTOISB}</td>
                                    <td>{TollPlaza.ISBTOLHR}</td>
                                    {/* <td>
                                        <button
                                            className="btn btn-success btn-sm me-2"
                                        >
                                            <HiOutlinePencilSquare />
                                        </button>
                                        <button
                                            className="btn btn-danger btn-sm"
                                        >
                                            <MdDelete />
                                        </button>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};

export default AllTollPlaza;
