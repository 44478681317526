// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// // import { HiOutlinePencilSquare } from "react-icons/hi2";
// // import { MdDelete } from "react-icons/md";

// const TimestampTraffic = () => {
//     const TimeStampData = [
//         { Time: '8-10', TotalVehicle: 1000, LHRTOISB: 650, ISBTOLHR: 450 },
//         { Time: '8-15', TotalVehicle: 1020, LHRTOISB: 670, ISBTOLHR: 450 },
//         { Time: '8-20', TotalVehicle: 980, LHRTOISB: 640, ISBTOLHR: 340 },
//         { Time: '8-25', TotalVehicle: 1040, LHRTOISB: 690, ISBTOLHR: 350 },
//         { Time: '8-30', TotalVehicle: 990, LHRTOISB: 660, ISBTOLHR: 330 },
//         { Time: '9-05', TotalVehicle: 1010, LHRTOISB: 680, ISBTOLHR: 330 },
//         { Time: '9-10', TotalVehicle: 980, LHRTOISB: 650, ISBTOLHR: 330 },
//         { Time: '9-15', TotalVehicle: 1005, LHRTOISB: 670, ISBTOLHR: 335 },
//         { Time: '9-20', TotalVehicle: 990, LHRTOISB: 660, ISBTOLHR: 330 },
//         {  Time: '9-25', TotalVehicle: 1015, LHRTOISB: 690, ISBTOLHR: 325 }
//     ];

//     return (
//         <>
//         <div className="mb-4 "><h3>TimeStamp</h3></div>
//             <table className="table table-striped table-bordered">
//                 <thead>
//                     <tr>
//                         {/* <th>Id#</th> */}
//                         <th>Time</th>
//                         <th>Total Vehicle</th>
//                         <th>LHR-ISB</th>
//                         <th>ISB-LHR</th>
                    
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {TimeStampData.map((TimeStamp, index) => (
//                         <tr key={index}>
//                             {/* <td>{TimeStamp.ID}</td> */}
//                             <td>{TimeStamp.Time}</td>
//                             <td>{TimeStamp.TotalVehicle}</td>
//                             <td>{TimeStamp.LHRTOISB}</td>
//                             <td>{TimeStamp.ISBTOLHR}</td>
//                             {/* <td>
//                       <button
//                     className="btn btn-success btn-sm me-2" >
//                       <HiOutlinePencilSquare />
//                     </button>
//                      <button
//                     className="btn btn-danger btn-sm"
//                     >
//                          <MdDelete   />
//                     </button>
//                 </td> */}
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </>
//     );
// };

// export default TimestampTraffic;





import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../Dashboard/Style/Style.css';
// import { HiOutlinePencilSquare } from "react-icons/hi2";
// import { MdDelete } from "react-icons/md";

const TimestampTraffic = () => {
    const TimeStampData = [
        { Time: '8-10', TotalVehicle: 1000, LHRTOISB: 650, ISBTOLHR: 450 },
        { Time: '8-15', TotalVehicle: 1020, LHRTOISB: 670, ISBTOLHR: 450 },
        { Time: '8-20', TotalVehicle: 980, LHRTOISB: 640, ISBTOLHR: 340 },
        { Time: '8-25', TotalVehicle: 1040, LHRTOISB: 690, ISBTOLHR: 350 },
        { Time: '8-30', TotalVehicle: 990, LHRTOISB: 660, ISBTOLHR: 330 },
        { Time: '9-05', TotalVehicle: 1010, LHRTOISB: 680, ISBTOLHR: 330 },
        { Time: '9-10', TotalVehicle: 980, LHRTOISB: 650, ISBTOLHR: 330 },
        { Time: '9-15', TotalVehicle: 1005, LHRTOISB: 670, ISBTOLHR: 335 },
        { Time: '9-20', TotalVehicle: 990, LHRTOISB: 660, ISBTOLHR: 330 },
        { Time: '9-25', TotalVehicle: 1015, LHRTOISB: 690, ISBTOLHR: 325 }
    ];

    return (
        <Card>
            <CardBody>
                <CardTitle tag="h3" className="mb-4">TimeStamp</CardTitle>
                <div className="table-responsive">
                    <table className="table table-striped  table-custom table-bordered mb-0">
                        <thead>
                            <tr>
                                {/* <th>Id#</th> */}
                                <th>Time</th>
                                <th>Total Vehicle</th>
                                <th>LHR-ISB</th>
                                <th>ISB-LHR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {TimeStampData.map((TimeStamp, index) => (
                                <tr key={index}>
                                    {/* <td>{TimeStamp.ID}</td> */}
                                    <td>{TimeStamp.Time}</td>
                                    <td>{TimeStamp.TotalVehicle}</td>
                                    <td>{TimeStamp.LHRTOISB}</td>
                                    <td>{TimeStamp.ISBTOLHR}</td>
                                    {/* <td>
                                        <button
                                            className="btn btn-success btn-sm me-2"
                                        >
                                            <HiOutlinePencilSquare />
                                        </button>
                                        <button
                                            className="btn btn-danger btn-sm"
                                        >
                                            <MdDelete />
                                        </button>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};

export default TimestampTraffic;


